<template>
  <div>
    <div class="card">
      <validation-observer ref="VFormTindakLanjut">
        <b-form @submit.prevent="doSubmit">

        <div class="card-header bg-info">
          <h6 class="card-title font-weight-semibold">Resep</h6>
        </div>
        
        <div class="card-body p-3" v-if="Object.keys(dataRMDokter||{}).length">
          <div class="row">
            <div class="col-md-6">
              <div class="card border mb-0">
                <div class="card-header bg- head_panel_red p-2">
                  <h6 class="font-weight-semibold text-dark card-title">Catatan Alergi Pasien</h6>
                </div>
                <div class="card-body py-2 px-3">
                  <ul class="mb-0 pl-3" v-if="dataRMDokter.arankm_has_alergi == 'Y'">
                    <li class="my-1" v-for="(v,k) in (dataRMDokter.arankm_alergi||[])" :key="k+'alergi'">
                      <span class="font-weight-semibold">({{v.jenis||"-"}})</span>
                      <span>{{v.name||"-"}}</span>
                    </li>
                  </ul>
                  <span v-else>Tidak ada Alergi</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border mb-0">
                <div class="card-header bg- head_panel_blue p-2">
                  <h6 class="font-weight-semibold text-dark card-title">Catatan Obat Rutin Pasien
                  </h6>
                </div>
                <div class="card-body py-2 px-3">
                  <span class="my-1" v-if="dataRMDokter.arankm_has_obat_rutin == 'Y'">{{dataRMDokter.arankm_keterangan_obat_rutin||"-"}}</span>
                  <span class="my-1" v-else> Tidak ada obat rutin </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <table class="table table-striped table-sm table-hover table-bordered">
            <thead>
            <tr>
                <th width="48">Aksi</th>
                <th width="160">Jenis</th>
                <th width="320">Nama</th>
                <th width="150">Jumlah</th>
                <th width="210">Frekuensi</th>
                <th>Keterangan</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                <td>
                <a href="javascript:;" @click="removeObat(v,k)"
                    class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                    class="icon-trash"></i></a>
                </td>
                <td>
                <v-select placeholder="Pilih Item" v-model="resepDokter[k].arantfarr_jenis"
                    :options="(user.levelId == uPerawatRanap || user.levelId == uPerawatJagaBangsal) ? isParent.Config.mr.configResepPerawatRanap : isParent.Config.mr.configResepRanap" label="text"
                    @input="changeJenis($event,k)" :reduce="v=>v.value">
                </v-select>
                <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                    v-model="resepDokter[k].arantfarr_jenis" :rules="{required : 1}" />
                </td>
                <td>
                <div class="d-flex">
                    <template v-if="resepDokter[k]['arantfarr_jenis'] == 1">
                    <v-select style="flex: 1;" class="med-selection select-paging"
                        placeholder="Pilih Item"
                        :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                        @open="countLen" @search="filterObat($event,k);countLen();"
                        v-model="resepDokter[k].arantfarr_item_id" :options="mObatPreferensi"
                        label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                        :reduce="v=>v.value"
                        :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                        <template slot="selected-option">
                        <span
                            :class="isAlergiLabel(v.arantfarr_nama)?'text-warning':''">{{ v.arantfarr_nama }}</span>
                        </template>
                        <template slot="option" slot-scope="option">
                        <span :class="isAlergiLabel(option.text)?'text-warning':''"
                            v-if="option.preferensi"><b>{{ option.text }}</b></span>
                        <span :class="isAlergiLabel(option.text)?'text-warning':''"
                            v-else>{{ option.text }}</span>
                        </template>
                        <template #list-footer>
                        <li class="list-paging">
                            <div class="d-flex align-items-center justify-content-between">
                            <b-button
                                @click="changePageObat(v.currentPage,k,'min','resepDokter')"
                                size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                <i class="icon-arrow-left22 text-white"></i></b-button>
                            <small>Halaman {{v.currentPage}} dari
                                {{ceilData(v.totalRows/10)}}</small>
                            <b-button
                                @click="changePageObat(v.currentPage,k,'plus','resepDokter')"
                                size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                <i class="icon-arrow-right22 text-white"></i></b-button>
                            </div>
                        </li>
                        </template>
                    </v-select>
                    <br />
                    </template>

                    <template v-else-if="resepDokter[k]['arantfarr_jenis'] == 2">
                    <v-select style="flex: 1;" class="med-selection select-paging"
                        placeholder="Pilih Item" v-model="resepDokter[k].arantfarr_item_id"
                        :options="(paginatedItems||[])" 
                        @open="resetAlkes" @search="filterAlkes($event,k)"
                        label="text"
                        @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                        :reduce="v=>v.value">
                        
                        <template slot="selected-option">
                            <span>{{ getConfigDynamic(mAlatKesehatan,resepDokter[k].arantfarr_item_id)||"-" }}</span>
                        </template>
                        
                        <template slot="option" slot-scope="option">
                            <span>{{option.text}}</span>
                        </template>
                        
                        <template #list-footer>
                            <li class="list-paging">
                                <div class="d-flex align-items-center justify-content-between">
                                <b-button
                                    @click="prevPage"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                    <i class="icon-arrow-left22 text-white"></i></b-button>
                                <small>Halaman {{currentPage}} dari
                                    {{totalPages}}</small>
                                <b-button
                                    @click="nextPage"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                    <i class="icon-arrow-right22 text-white"></i></b-button>
                                </div>
                            </li>
                        </template>

                    </v-select>
                    </template>

                    <template v-else-if="resepDokter[k]['arantfarr_jenis'] == 3">
                    <v-select style="flex: 1;" class="med-selection select-paging"
                        placeholder="Pilih Item" v-model="resepDokter[k].arantfarr_item_id"
                        :options="mRacikan" label="text"
                        @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                        :reduce="v=>v.value">
                        <template slot="selected-option" slot-scope="option">
                        <span>{{ option.text }}</span>
                        </template>
                        <template slot="option" slot-scope="option">
                        <span>{{ option.text }}</span>
                        </template>
                    </v-select>
                    </template>

                    <template v-else-if="resepDokter[k]['arantfarr_jenis'] == 5">
                        <v-select style="flex: 1;" class="med-selection select-paging"
                            placeholder="Pilih Item" v-model="resepDokter[k].arantfarr_item_id"
                            :options="(paginatedItemsInfus||[])" 
                            @open="resetInfus" @search="filterInfus($event,k)"
                            label="text"
                            @input="selectJumlahSatuanInfus($event,k)" :clearable="true"
                            :reduce="v=>v.value">
                            
                            <template slot="selected-option">
                                <span>{{ getConfigDynamic(mAlatInfus,resepDokter[k].arantfarr_item_id)||"-" }}</span>
                            </template>
                            
                            <template slot="option" slot-scope="option">
                                <span>{{option.text}}</span>
                            </template>
                            
                            <template #list-footer>
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button
                                        @click="prevPageInfus"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{currentPage}} dari
                                        {{totalPagesInfus}}</small>
                                    <b-button
                                        @click="nextPageInfus"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                            </template>

                        </v-select>
                    </template>

                    <a href="javascript:;" v-if="resepDokter[k]['arantfarr_jenis'] !== 3"
                    @click="resepDokter[k]['arantfarr_item_id'] = 99999; resepDokter[k]['arantfarr_nama'] = 'Lainnya'"
                    v-b-tooltip.hover="'Pilih Lainnya'"
                    class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                        class="icon-plus-circle2"></i></a>
                </div>
                <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                    v-model="resepDokter[k]['arantfarr_item_id']" :rules="{required : 1}" />

                <div v-if="resepDokter[k]['arantfarr_item_id'] == 99999">
                    <b-form-input type="text" v-model="resepDokter[k]['arantfarr_item_lainnya']"
                    class="form-control" id="itemCount" />
                    <VValidate :name="'Item Lainnya '+(k+1)"
                    message="Nama Item Lainnya Harus Diisi"
                    v-model="resepDokter[k]['arantfarr_item_lainnya']" :rules="{required : 1}" />
                </div>

                <a v-if="resepDokter[k].arantfarr_jenis == 3 && resepDokter[k]['arantfarr_item_id']"
                    href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title=""
                    data-toggle="modal" data-target="#modalRacikanDetail"
                    class="font-weight-semibold border-bottom"
                    data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                <small class="text-warning"
                    v-if="isAlergiLabel(resepDokter[k].arantfarr_nama) ">Obat ini termasuk dalam
                    kategori alergi</small>

                    
                </td>
                <td>
                <div class="input-group">
                    <b-form-input type="text" v-model="resepDokter[k]['arantfarr_jumlah']"
                    :formatter="isParent.number" class="form-control" id="itemCount" />
                    <div class="input-group-append">
                    <span class="input-group-text">{{resepDokter[k]['arantfarr_satuan']}}</span>
                    </div>
                </div>

                <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                    v-model="resepDokter[k]['arantfarr_jumlah']" :rules="{required : 1}" />
                </td>
                <td>
                <span class="d-flex">
                    <v-select style="flex: 1;" class="med-selection"
                    placeholder="Pilih Frekuensi" v-model="resepDokter[k].arantfarr_frekuensi"
                    :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                    </v-select>
                    <a href="javascript:;" @click="resepDokter[k]['arantfarr_frekuensi'] = 99999"
                    v-b-tooltip.hover="'Pilih Lainnya'"
                    class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                        class="icon-plus-circle2"></i></a>
                </span>
                <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                    v-model="resepDokter[k]['arantfarr_frekuensi']" 
                    v-if="resepDokter[k].arantfarr_jenis != 2"
                    :rules="{required : 1}" />

                <div v-if="resepDokter[k]['arantfarr_frekuensi'] == 99999">
                    <b-form-input type="text"
                    v-model="resepDokter[k]['arantfarr_frekuensi_lainnya']" class="form-control"
                    id="itemCount" />
                    <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                    message="Frekuensi Lainnya Harus Diisi"
                    v-if="resepDokter[k].arantfarr_jenis != 2"
                    v-model="resepDokter[k]['arantfarr_frekuensi_lainnya']"
                    :rules="{required : 1}" />
                </div>
                </td>
                <td>
                <b-textarea v-model="resepDokter[k].arantfarr_keterangan" name="itemNotes"
                    id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                    class="form-control"></b-textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td class="text-center" colspan="99">
                <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                    data-toggle="modal" data-target="#modalResep">
                    <i class="icon-plus2 mr-1 align-middle"></i>
                    <span class="align-middle">Tambah Resep</span>
                </a>
                <a href="javascript:;" @click="openTemplateResep" v-if="isParent.row.is_have_template_resep" class="btn alpha-warning ml-1"
                    data-toggle="modal" data-target="#modalResep">
                    <i class="fas fa-receipt mr-1 align-middle"></i>
                    <span class="align-middle">Ambil Dari Template</span>
                </a>
                </td>
            </tr>
            </tfoot>
        </table>

        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-8">
              <div>
                <label for="">Catatan Resep</label>
                <b-form-textarea v-model="rowData.arantfar_resep_catatan" class="form-control" rows="3"></b-form-textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>

        </b-form>
      </validation-observer>

    </div>

    <b-modal v-model="openRacikan" :title="'Komposisi Racikan'" size="md" hide-footer>
        <div class="modal-body p-0 table-responsive">
            <p>{{rowRacikan.arantfarr_jumlah||"-"}} {{rowRacikan.arantfarr_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri
            dari:</p>
            <table class="table table-striped table-sm table-hover table-bordered">
            <thead>
                <tr>
                <th width="40">#</th>
                <th>Nama Obat</th>
                <th>Qty</th>
                <th>Frekuensi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in rowRacikan.arantfarr_racikan" :key="k+'rowracik'">
                <td>{{k+1}}</td>
                <td>{{getObat(v.value)||"-"}}</td>
                <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="modal-footer p-0 mt-3">

            <a href="javascript:;" @click="doOpenFormRacikan()" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            <div class="icon-pencil7"></div>
            Ubah Komposisi Racikan
            </a>
            <button type="button" @click="openRacikan = false" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
        </div>
    </b-modal>

    <validation-observer ref="VFormRacikan">
    <b-form @submit.prevent="doSubmitRacikan">
        <b-modal v-model="openFormRacikan" :title="'Ubah Komposisi Racikan'" size="lg" hide-footer>
        <div class="modal-body p-0">
            <div class="alert alert-info">
            <span>Saat ini Anda sedang melakukan perubahan komposisi racikan
                <strong>{{rowRacikan.mrd_nama_racikan||"-"}}</strong> milik
                <strong>{{rowRacikan.bu_full_name||"-"}}</strong></span>
            </div>
            <div class="form-group">
            <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
            <table class="table table-bordered table-striped table-sm">
                <thead>
                <tr>
                    <th width="48">#</th>
                    <th width="33%">Obat</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in rowRacikan.arantfarr_racikan" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                    <v-select placeholder="Pilih Obat" @input="selectObat($event,k)"
                        v-model="rowRacikan.arantfarr_racikan[k]['value']" :options="getMasterObat(rowRacikan.arantfarr_racikan,k)"
                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                    <VValidate :name="'Obat #'+(k+1)" message="Obat Harus Diisi"
                        v-model="rowRacikan.arantfarr_racikan[k]['value']" :rules="{required : 1}" />
                    </td>
                    <td>
                    <div class="input-group">
                        <input v-model="rowRacikan.arantfarr_racikan[k]['dosis']" type="text" class="form-control"
                        placeholder="cth. 120">
                        <div class="input-group-append"><input style="width: 90px;"
                            v-model="rowRacikan.arantfarr_racikan[k]['satuan_dosis']" type="text" class="form-control"
                            placeholder="cth. Kaplet"></div>
                    </div>
                    </td>
                    <td>
                    <div class="input-group">
                        <b-form-input type="text" v-model="rowRacikan.arantfarr_racikan[k]['jumlah']"
                        :formatter="isParent.number" class="form-control" placeholder="cth. 5" />
                        <div class="input-group-append"><span
                            class="input-group-text">{{rowRacikan.arantfarr_racikan[k]['satuan_jumlah']||"-"}}</span></div>
                    </div>

                    <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                        v-model="rowRacikan.arantfarr_racikan[k]['jumlah']" :rules="{required: 1}" />
                    </td>
                    <td>
                    <a href="javascript:;" @click="rowRacikan.arantfarr_racikan.splice(k,1)"
                        class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                        data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="9" class="text-center">
                    <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Obat</span>
                    </a>
                    </td>
                </tr>
                </tfoot>
            </table>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
            <div>
                <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                <div class="input-group">
                <b-form-input type="text" :formatter="isParent.number" v-model="rowRacikan.arantfarr_jumlah"
                    class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan" />
                <div class="input-group-append">
                    <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="rowRacikan.arantfarr_satuan"
                    :options="isParent.mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
                </div>
                <VValidate name="Jumlah" v-model="rowRacikan.arantfarr_jumlah" :rules="{required : 1}" />
                <VValidate name="Satuan" v-model="rowRacikan.arantfarr_satuan" :rules="{required : 1}" />
            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            Batal
            </a>
            <button type="submit" @click="doSubmitRacikan" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
        </div>
        </b-modal>
    </b-form>
    </validation-observer>

    <validation-observer ref="VFormTemplate">
    <b-modal v-model="openTemplate" @ok.prevent="submitTemplate" :title="'Template Resep'" size="md" ok-title="Pilih Template">
      <div class="row">
        <div class="col-md-12">
          <template v-if="templateResep">
            <table class="table table-bordered table-striped table-hover table-sm patient-table">
            <thead>
              <tr>
                <th width="20"></th>
                <th>Nama</th>
                <th>Rincian</th>
                <th width="80">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (templateResep||[])" :key="k">
                <td>
                  <b-form-checkbox v-model="v.checked" :value="true" :unchecked-value="null"></b-form-checkbox>
                </td>
                <td>{{v.mtr_name}}</td>
                <td>
                  <span class="d-block font-weight-bold" v-if="v.mtr_total_obat">Total Obat : {{v.mtr_total_obat}}</span>
                  <span class="d-block font-weight-bold" v-if="v.mtr_total_alkes">Total Alkes : {{v.mtr_total_alkes}}</span>
                  <span class="d-block font-weight-bold" v-if="v.mtr_total_infus">Total Infus : {{v.mtr_total_infus}}</span>
                </td>
                <td>
                    <a @click="openDetail(k,v.data)"
										href="javascript:;"
										title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-center ml-1" v-b-tooltip.hover
										><i class="icon-eye"></i></a>
                </td>
              </tr>
              <tr v-if="!(templateResep||[]).length">
                <td colspan="4" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
            </table>
          </template>
          <span class="mt-1 mb-1 text-center" v-else>Loading ... </span>
        </div>
      </div>
    </b-modal>
    </validation-observer>

    <b-modal v-model="openDetailIsi" :title="'Detail Resep'" size="lg" ok-title="Pilih Template" @ok.prevent="selectOneTemplate()">
      <div class="row">
        <div class="col-md-12">
           <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Jenis</th>
                  <th>Nama</th>
                  <th>Jumlah</th>
                  <th>Frekuensi</th>
                  <th>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in (dataDetail||[])" :key="k">
                  <td>{{getConfigDynamic(Config.mr.configResep,v.arantfarr_jenis)||"-"}}</td>
                  <td>{{v.arantfarr_nama||"-"}}</td>
                  <td>{{v.arantfarr_jumlah||"-"}} {{v.arantfarr_satuan}}</td>
                  <td>{{v.mdo_name||v.arantfarr_frekuensi_lainnya||"-"}}</td>
                  <td>{{v.arantfarr_keterangan||"-"}}</td>
                </tr>
                <tr v-if="!(dataDetail||[]).length">
                  <td colspan="5" class="text-center">Tidak ada data</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </b-modal>

    
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import { stringSimilarity } from "string-similarity-js"

export default{
  extends: GlobalVue,
  data() {
    return {
      resepDokter: [],
      rowData: {},
      mObatPreferensi: [],
      mObat: [],
      mRacikan: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mDosis: [],
      mrValidation: {},

      openFormRacikan: false,
      obatLen: 0,
      currentPage: 1,
      openRacikan: false,
      rowRacikan: {},

      openRiwayatObat: false,
      selectedObat: '',
      searchObat: '',
      dataRMDokter: {},
      
      pageSize: 10,
      searchQuery: '',
      
      templateResep: false,
      openTemplate: false,
      openTemplateDetail: false,
      dataDetail: [],
      openDetailIsi: false,
      selectedDetail: null,
    }
  },
  computed: {
    isParent(){
      return this.$parent
    },
    filteredItems() {
        return this.mAlatKesehatan.filter(item =>
            item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
    },
    paginatedItems() {
        const startIndex = (this.currentPage - 1) * this.pageSize
        const endIndex = startIndex + this.pageSize
        return this.filteredItems.slice(startIndex, endIndex)
    },
    totalPages() {
        return Math.ceil(this.filteredItems.length / this.pageSize) || 1
    },


    
    filteredItemsInfus() {
        return this.mAlatInfus.filter(item =>
            item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
    },
    paginatedItemsInfus() {
        const startIndex = (this.currentPage - 1) * this.pageSize
        const endIndex = startIndex + this.pageSize
        return this.filteredItemsInfus.slice(startIndex, endIndex)
    },
    totalPagesInfus() {
        return Math.ceil(this.filteredItemsInfus.length / this.pageSize) || 1
    },

    
    selectedTemplate(){
      let selected = []
      for (let i = 0; i < (this.templateResep||[]).length; i++) {
        if(this.templateResep[i]['checked']){
          for (let j = 0; j < (this.templateResep[i]['data']||[]).length; j++) {
            let dataSelected = this.templateResep[i]['data'][j]
            dataSelected.arantfarr_code = null
            selected.push(dataSelected)
          }
        }
      }
      return selected
    }
  },
  methods: {
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.resepNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.resepNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.resepNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },

    changePageObat(page, k, act, col) {
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
        }

        let data = {
            type: 'select-paging-obat',
            page: page,
            idDokter: this.isParent.rowReg.aranr_dokter_id,
            search: this[col][k]['search']
        }
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.mObatPreferensi = res.data.data
        })
    },

    openObat(v = null) {
        let data = {
            arantfarr_aranr_id: this.isParent.rowReg.aranr_id,
            arantfarr_arantfar_id: this.$route.params.resepNo,
            arantfarr_jenis: (this.user.levelId == this.uPerawatRanap || this.user.levelId == this.uPerawatJagaBangsal) ? 2 : 1,
            arantfarr_item_id: null,
            arantfarr_nama: null,
            arantfarr_jumlah: null,
            arantfarr_satuan: null,
            arantfarr_frekuensi: null,
            arantfarr_frekuensi_lainnya: null,
            arantfarr_keterangan: null,
            arantfarr_code : null,
            arantfarr_is_alergi: null,
            arantfarr_signa1: null,
            arantfarr_signa2: null,
            id: null
        }
        if (!this.rowData.isEdit) {
            data.type = 'add-resep-dokter'
            Gen.apiRest(
                "/do/" + this.isParent.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.resepDokter.push(resp.row)
            })
        } else {
            this.resepDokter.push(data)
        }

    },
    
    removeObat(v, k) {
        if (!this.rowData.isEdit) {
            let data = {
                type: 'remove-resep-dokter',
                id: v.arantfarr_id
            }
            Gen.apiRest(
                "/do/" + this.isParent.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                this.resepDokter.splice(k, 1)
            })
        } else {
            this.resepDokter.splice(k, 1)
        }
    },
    
    resetObat() {
      
        this.loadingOverlay = true
        let data = {
            id: this.rowData.arantfarr_id,
            type: 'get-resep-dokter'
        }
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            let resp = res.data
            this.resepDokter = resp.data
            this.loadingOverlay = false
        })
    },

    
    toRacikan(v) {
        this.loadingOverlay = true
        let data = {
            id: v.arantfarr_item_id,
            type: 'get-racikan-data'
        }
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.loadingOverlay = false
            this.rowRacikan = {
                ...v,
                ...res.data.row
            }
            this.isParent.mObat = res.data.mObat
            this.openRacikan = true
        })
    },
    
    doSubmitRacikan() {
      this.$refs['VFormRacikan'].validate().then(success => {
        if (success) {
          let data = this.rowRacikan
          let idx = this.resepDokter.findIndex(x => x.arantfarr_id == this.rowRacikan.arantfarr_id)
          this.resepDokter[idx]['arantfarr_satuan'] = this.rowRacikan.arantfarr_satuan
          this.resepDokter[idx]['arantfarr_jumlah'] = this.rowRacikan.arantfarr_jumlah
          this.resepDokter[idx]['arantfarr_racikan'] = this.rowRacikan.arantfarr_racikan
          this.openFormRacikan = false
        } else {
          setTimeout(() => {
          let inv = []
          let el = document.querySelectorAll('.label_error')
          for (let i = 0; i < (el || []).length; i++) {
              if (el[i].style.display !== 'none') {
              inv.push(el[i].id)
              }
          }
          if (inv.length) document.getElementById(inv[0]).scrollIntoView({
              behavior: 'smooth',
              block: 'center'
          })
          }, 500)
        }
      })
    },
    
    doOpenFormRacikan() {
        let data = {
            type: 'get-all-obat'
        }
        Gen.apiRest(
            "/do/" + this.isParent.modulePage, {
                data: data
            },
            "POST"
        ).then(res => {
            this.isParent.mObat = res.data.data
            this.openFormRacikan = true
            this.openRacikan = false
        })
    },
    
    addObat() {
        this.rowRacikan.arantfarr_racikan.push({
            value: null,
            dosis: null,
            satuan_dosis: null,
            jumlah: null,
            satuan_jumlah: null,
        })
    },
    
    resetData() {
        this.searchObat = ''
        this.openFormRacikan = false
        this.apiGetForm()
    },

    
    changeJenis(e, k) {
        this.resepDokter[k].arantfarr_item_id = null
        this.resepDokter[k].arantfarr_frekuensi = null
        this.resepDokter[k].arantfarr_frekuensi_lainnya = null
    },
    ceilData(value) {
        return Math.ceil(value)
    },
    selectObat(e, k) {
        let index = this.isParent.mObat.findIndex(x => x.value == e)
        if (index !== -1) {
            let data = this.isParent.mObat[index]
            this.rowRacikan.arantfarr_racikan[k]['dosis'] = data.dosis
            this.rowRacikan.arantfarr_racikan[k]['satuan_dosis'] = data.satuan_dosis
            this.rowRacikan.arantfarr_racikan[k]['satuan_jumlah'] = data.satuan
        }
    },
    filterObat: _.debounce(function (e, k) {
        if (e) {
            this.resepDokter[k]['search'] = e
            let id = []
            for (let i = 0; i < (this.resepDokter || []).length; i++) {
                if (this.resepDokter[i]['arantfarr_item_id'] && this.resepDokter[i]['arantfarr_jenis'] == 1) {
                id.push(this.resepDokter[i]['arantfarr_item_id'])
                }
            }
            Gen.apiRest('/do/' + this.isParent.modulePage, {
                data: {
                    type: 'select-obat',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.mObatPreferensi = res.data.data
                this.resepDokter[k]['currentPage'] = 1
                this.resepDokter[k]['totalRows'] = res.data.totalRows
            })
        }
    }, 10),

    
    selectJumlahSatuan(e, k) {
        let data = {
            id: e,
            type: 'get-by-obat'
        }
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].arantfarr_satuan = res.data.satuan
            this.resepDokter[k].arantfarr_nama = res.data.nama
            this.resepDokter[k].arantfarr_racikan = null

            this.resepDokter[k].arantfarr_dosis = res.data.dosis
            this.resepDokter[k].arantfarr_satuan_dosis = res.data.satuan_dosis
            this.resepDokter[k].arantfarr_item_lainnya = null

            this.isAlergi(res.data.nama, k)
        })
    },
    
    isAlergi(nama, k) {
        let data = []
        for (let i = 0; i < (this.dataRMDokter.ausd_alergi || []).length; i++) {
            if (this.dataRMDokter.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.dataRMDokter.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }

        this.resepDokter[k]['arantfarr_is_alergi'] = isWarning ? "Y" : "N"
    },
    selectJumlahSatuanAlkes(e, k) {
        let data = {
            id: e,
            type: 'get-by-alkes'
        }
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].arantfarr_satuan = res.data.satuan
            this.resepDokter[k].arantfarr_nama = res.data.nama
            this.resepDokter[k].arantfarr_racikan = null

            this.resepDokter[k].arantfarr_dosis = null
            this.resepDokter[k].arantfarr_satuan_dosis = null
            this.resepDokter[k].arantfarr_frekuensi = null
            this.resepDokter[k].arantfarr_frekuensi_lainnya = null
            this.resepDokter[k].arantfarr_item_lainnya = null
        })
    },
    
    selectJumlahSatuanInfus(e, k) {
        let data = {
            id: e,
            type: 'get-by-infus'
        }
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].arantfarr_satuan = res.data.satuan
            this.resepDokter[k].arantfarr_nama = res.data.nama
            this.resepDokter[k].arantfarr_racikan = null

            this.resepDokter[k].arantfarr_dosis = null
            this.resepDokter[k].arantfarr_satuan_dosis = null
            this.resepDokter[k].arantfarr_frekuensi = null
            this.resepDokter[k].arantfarr_frekuensi_lainnya = null
            this.resepDokter[k].arantfarr_item_lainnya = null
        })
    },
    selectJumlahSatuanRacikan(e, k) {
        let data = {
            id: e,
            type: 'get-by-racikan'
        }
        Gen.apiRest(
        "/do/" + this.isParent.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].arantfarr_satuan = res.data.satuan
            this.resepDokter[k].arantfarr_nama = res.data.nama
            this.resepDokter[k].arantfarr_racikan = res.data.data

            this.resepDokter[k].arantfarr_dosis = null
            this.resepDokter[k].arantfarr_satuan_dosis = null
            this.resepDokter[k].arantfarr_frekuensi = null
            this.resepDokter[k].arantfarr_frekuensi_lainnya = null
            this.resepDokter[k].arantfarr_item_lainnya = null
        })
    },
    isAlergiLabel(nama) {
        let data = []
        for (let i = 0; i < (this.dataRMDokter.ausd_alergi || []).length; i++) {
            if (this.dataRMDokter.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.dataRMDokter.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }
        return isWarning
    },
    countLen() {
      setTimeout(() => {
          this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },

    autoSaveResepObat: _.debounce(function (data) {
        let dataPost = {
            type: 'auto-save-resep-obat',
            data: data
        }
        if (!this.rowData.isEdit) {
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: dataPost
                },
                "POST"
            )
        }
    }, 1000),
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if (!this.rowData.isEdit) {
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            )
        }
    }, 1000),

    doSubmit(){
      this.$refs['VFormTindakLanjut'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data'
              data.resepDokter = this.resepDokter

              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.isParent.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: this.isParent.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                  })
              })
            }
          }).catch(err => {
            this.loadingOverlay = false
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.isParent.doSetAlertForm(err)
          })
        }
        
      })
    },
    
    filterInfus(e,k){
        this.searchQuery = e
        this.currentPage = 1
    },
    resetInfus(){
      this.currentPage = 1 
      this.searchQuery = ''
    },
    nextPageInfus() {
        if (this.currentPage < this.totalPagesInfus) {
            this.currentPage++
        }
    },
    prevPageInfus() {
        if (this.currentPage > 1) {
            this.currentPage--
        }
    },


    filterAlkes(e,k){
        this.searchQuery = e
        this.currentPage = 1
    },
    resetAlkes(){
      this.currentPage = 1 
      this.searchQuery = ''
    },
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++
        }
    },
    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--
        }
    },
    
    getObat(val){
        let index = this.isParent.mObat.findIndex(x => x.value == val)        
        if(index !== -1){
            return this.isParent.mObat[index]['text']
        }else{
            return null
        }
    },
    
    getMasterObat(data, k) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
          return k2 != k
      }), v2 => {
          return v2.value
      })
          return _.filter(this.isParent.mObat, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
      })
    },


    openTemplateResep(){
      let idRefTemplate = this.user.levelId == 1 ? this.isParent.rowReg.aranr_dokter_id : this.rowData.arantfar_created_by
      this.templateResep = false
      let data = {
          id_dokter: idRefTemplate,
          type: 'get-template-resep'
      }
      Gen.apiRest(
      "/do/" + 'RanapPasienPermintaanResep', {
          data: data
      },"POST").then(res => {
          this.openTemplate = true
          this.templateResep = res.data.data
      })
      // kalo super admin biar bisa ngetrack pake template dpjp
    },
    openDetail(k,data){
      this.selectedDetail = k
      this.dataDetail = data
      this.openDetailIsi = true
    },
    selectOneTemplate(){
        this.templateResep[this.selectedDetail]['checked'] = true
        this.submitTemplate()
        this.openDetailIsi = false
    },
    submitTemplate(){
      if(!(this.selectedTemplate||[]).length){
        return this.$swal({
          icon: 'error',
          title: 'Wajib Memilih Satu Item Template'
        })
      }
      let dataPost = this.selectedTemplate
      for (let i = 0; i < (dataPost||[]).length; i++) {
        dataPost[i]['arantfarr_code'] = null
        dataPost[i]['arantfarr_aranr_id'] = this.rowData.arantfar_aranr_id
        dataPost[i]['arantfarr_arantfar_id'] = this.rowData.arantfar_id
        dataPost[i]['arantfarr_arancsa_id'] = null

        dataPost[i]['arantfarr_is_alergi'] = null
        dataPost[i]['arantfarr_signa1'] = null
        dataPost[i]['arantfarr_signa2'] = null
      }

      this.$swal({
          html: 'Menyalin resep...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
              this.$swal.showLoading()
          }
      })
      
      if (!this.rowData.isEdit) {
        let data = {
          dataPost : dataPost,
          arantfar_id : this.rowData.arantfar_id,
          type : 'replace-resep-from-template'
        }
        Gen.apiRest(
            "/do/" + 'RanapPasienPermintaanResep', {
            data: data
            },
            "POST"
        ).then(res => {
            this.resepDokter = res.data.data
            this.$nextTick(() => {
              this.$swal().close()
            })
            this.openTemplate = false
        })
      } else {
        this.resepDokter = dataPost
        this.$nextTick(() => {
          this.$swal().close()
        })
        this.openTemplate = false
      }
    }

      
  },
  mounted() {
    this.apiGetForm()
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    resepDokter: {
        handler(v) {
            this.autoSaveResepObat(v)
        },
        deep: true
    },
  }

}
</script>