<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <template v-if="isListDetail">
       <div class="page-title pt-0 pb-3">
          <a href="javascript:;" @click="$router.push({ name: 'RanapPasienPerawatan' })" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        
        <b-tabs @input="changeTab($event)" :value="activeTabs" nav-class="nav-tabs-bottom nav-justified mt-2 mb-0 bg-white">
          <b-tab title="Permintaan Resep">
            <div class="row g-2">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-white">
                    <div class="d-flex align-items-center">
                      <h5 class="card-title font-weight-semibold mr-auto">Permintaan Resep</h5>
                      
                      <div class="col-md-3">
                        <b-form-group class="mb-0">
                          <v-select placeholder=" -- Pilih Status -- " @input="apiGet(activeTabs)" v-model="statusFarmasi"
                            :options="mStatusPermintaanResep" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-auto">
                          <div class="form-group mb-0">
                              <button @click="apiGet(activeTabs)" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                          </div>
                      </div>

                      <a href="javascript:;" @click="downloadCatatanPemberian()" class="btn bg-success-600 btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-file-download"></i></b>Unduh Catatan Pemberian Resep</a>
                      <a href="javascript:;" v-if="moduleRole('add')" @click="addTindakan()" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                        <b><i class="icon-plus2"></i></b>Tambah Resep</a>
                    </div>
                  </div>

                  <div class="card-body" v-if="(checkedObat||[]).length" >
                    <a href="javascript:;" @click="openModalStop=true" v-if="moduleRole('stop_obat')" class="btn bg-danger-600 btn-labeled btn-labeled-left">
                      <b><i class="fa-solid fa-stop"></i></b>Stop Obat</a>
                  </div>
                  <table class="table table-striped table-sm  table-bordered">
                    <thead>
                      <tr>
                        <th>Tanggal</th>
                        <th>Nama</th>
                        <th>Jumlah</th>
                        <th>Frekuensi</th>
                        <th>Keterangan</th>
                        <th>Status</th>
                        <th>PPA/Nama</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody v-for="(v,k) in (dataPermintaanResep||[])" :key="k">
                      <tr>
                        <th colspan="8" class="table-info">
                        <em v-if="v.arantfar_submit_date">{{v.arantfar_submit_date | moment("DD MMMM YYYY, HH:mm")}}</em>
                        <em v-else>{{v.arantfar_created_date | moment("DD MMMM YYYY, HH:mm")}}</em>

                        <span v-if="v.arantfar_is_draft == 'Y'" class="ml-2 badge badge-warning">Draft</span>
                        
                        <!--
                        <a href="javascript:;" v-if="v.arantfar_is_draft == 'Y' && moduleRole('hapus')" @click="hapusTindakan(v)" class="text-danger ml-2" v-b-tooltip.hover title="Hapus Tindakan"><i class="icon-bin"></i></a>
                        
                        <a href="javascript:;" @click="toForm(v)" class="text-info ml-2" v-b-tooltip.hover title="Edit Tindakan"><i class="icon-pencil4"></i></a>
                        -->
                        </th>
                      </tr>
                      <tr v-for="(v1,k1) in (v.data||[])" :key="k+k1">
                        <td>
                          <div class="custom-control custom-checkbox" v-if="v1.arantfarr_status == 'PROSES' && v1.arantfarr_is_stop != 'Y'">
                            <b-form-checkbox v-model="v1.checked" class="form-check-input-styled" :name="'obat'+(k+k1)" />
                          </div>
                        </td>
                        <td>
                          <strong>{{getConfigDynamic(Config.mr.configResepRanap,v1.arantfarr_jenis)||"-"}}</strong>
                        {{v1.arantfarr_nama||"-"}}</td>
                        <td>{{v1.arantfarr_jumlah||"-"}}</td>
                        <td>{{v1.mdo_name||"-"}}</td>
                        <td>{{v1.arantfarr_satuan||"-"}}</td>
                        <td>
                          <span v-if="v.arantfar_is_draft == 'Y'" class="badge badge-warning">Draft</span>
                          <template v-else>
                            <template v-if="v1.arantfarr_is_stop == 'Y'" >
                              <span v-b-tooltip.hover :title="v1.arantfarr_is_stop_reason" class="badge badge-danger">Obat Dihentikan</span>
                            </template>
                            <template v-else>
                              <span v-if="v1.arantfarr_status == 'WAITING'" class="badge badge-warning">Belum Diproses</span>       
                              <span v-else-if="v1.arantfarr_status == 'PROSES'" class="badge badge-info">Berjalan</span>
                              <span v-else-if="v1.arantfarr_status == 'DONE'" class="badge badge-success">Sudah diberikan</span>
                            </template>

                          </template>             
                        </td>
                        <td>{{v.created_by||"-"}}</td>
                        <td v-if="k1==0" :rowspan="(v.data||[]).length + 1" style="width:4%">
                          <template v-if="v.arantfar_is_non_obat != 'Y'">
                          <a href="javascript:;" @click="toModal(v,'xl','Catatan Pemberian Obat','FormCatatanPemberian')" v-if="moduleRole('tambah_catatan') && (v1.arantfarr_status == 'PROSES' || v1.arantfarr_status == 'DONE')" class="btn btn-icon btn-sm rounded-round btn-sm alpha-indigo border-indigo text-indigo-800 mb-1" v-b-tooltip.hover
                            title="Tambah Catatan Pemberian Obat">
                            <i class="fa-solid fa-pencil"></i>
                          </a>
                          <a href="javascript:;" @click="toModal(v,'xl','Catatan Pemberian Obat','FormCatatanPemberianDetail')" v-if="moduleRole('lihat_catatan') && (v1.arantfarr_status == 'PROSES' || v1.arantfarr_status == 'DONE')" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover
                            title="Lihat Catatan Pemberian Obat">
                            <i class="fas fa-file-prescription"></i>
                          </a>
                          </template>
                          
                          <a href="javascript:;" v-if="moduleRole('edit') && (v.arantfar_status != 'DONE'&&v.arantfar_status != 'PROSES')" @click="toForm(v)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></a>

                          <a href="javascript:;" v-if="v.arantfar_status != 'WAITING'" @click="unduhDokumen(v.arantfar_id)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover title="Unduh Dokumen"><i class="icon-file-download"></i></a>
                                                
                          <a href="javascript:;" v-if="v.arantfar_is_draft == 'Y' && moduleRole('hapus')" @click="hapusTindakan(v)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-danger border-danger text-danger-800 mb-1" v-b-tooltip.hover title="Hapus"><i class="icon-bin"></i></a>
                        </td>
                      </tr>
                      <tr v-if="!(v.data||[]).length">
                        <td colspan="7" class="text-center">
                          <p class="mt-3">Belum ada data obat</p>
                        </td>
                        <td> 
                          <a href="javascript:;" v-if="(v.arantfar_created_by == user.id || user.levelId == 1) && (moduleRole('edit') && (v.arantfar_status != 'DONE'&&v.arantfar_status != 'PROSES'))" @click="toForm(v)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="(v.arantfar_created_by == user.id || user.levelId == 1) && (v.arantfar_is_draft == 'Y' && moduleRole('hapus'))" @click="hapusTindakan(v)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-danger border-danger text-danger-800 mb-1" v-b-tooltip.hover title="Hapus"><i class="icon-bin"></i></a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="!(dataPermintaanResep||[]).length">
                      <tr>
                        <td colspan="99" class="text-center">
                          <p>Tidak ada Data</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Permintaan Resep Pulang">
            <div class="row g-2">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-white">
                    <div class="d-flex align-items-center">
                      <h5 class="card-title font-weight-semibold mr-auto">Permintaan Resep Pulang</h5>
                      <div class="col-md-auto">
                          <div class="form-group mb-0">
                              <button @click="apiGet(activeTabs)" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                          </div>
                      </div>
                    </div>
                  </div>

                  <table class="table table-striped table-sm  table-bordered">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Jumlah</th>
                        <th>Frekuensi</th>
                        <th>Keterangan</th>
                        <th>Status</th>
                        <th>PPA/Nama</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody v-for="(v,k) in (dataPermintaanResepPulang||[])" :key="k">
                      <tr>
                        <th colspan="7" class="table-info">
                        <em v-if="v.aranrrp_submit_date">{{v.aranrrp_submit_date | moment("DD MMMM YYYY, HH:mm")}}</em>
                        <em v-else>{{v.aranrrp_created_date | moment("DD MMMM YYYY, HH:mm")}}</em>

                        <span v-if="v.aranrrp_is_draft == 'Y'" class="ml-2 badge badge-warning">Draft</span>
                        
                        </th>
                      </tr>
                      <tr v-for="(v1,k1) in (v.data||[])" :key="k+k1">
                        <td>
                          <strong>{{getConfigDynamic(Config.mr.configResepRanap,v1.aranrrpr_jenis)||"-"}}</strong>
                        {{v1.aranrrpr_nama||"-"}}</td>
                        <td>{{v1.aranrrpr_jumlah||"-"}}</td>
                        <td>{{v1.mdo_name||"-"}}</td>
                        <td>{{v1.aranrrpr_satuan||"-"}}</td>
                        <td>
                            <span v-if="v.aranrrp_status == 'WAITING'" class="d-block badge badge-warning">Belum Diproses</span>       
                            <span v-else-if="v.aranrrp_status == 'PROSES'" class="d-block badge badge-info">Berjalan</span>
                            <span v-else-if="v.aranrrp_status == 'DONE'" class="d-block badge badge-primary">Sudah diberikan oleh farmasi</span>
                            <span v-else-if="v.aranrrp_status == 'DELIVERED'" class="d-block badge badge-success">Sudah diberikan ke Pasien</span>
                            <span v-else-if="v.aranrrp_status == 'VOID'" class="d-block badge badge-danger">Dibatalkan</span>

                            <span class="d-block">{{v.aranrrp_notes}}</span>
                        </td>
                        <td>{{v.created_by||"-"}}</td>
                        <td v-if="k1==0" :rowspan="(v.data||[]).length + 1" style="width:4%">

                          <a href="javascript:;" @click="toModalV2(v,'xl','Catatan Pemberian Obat','FormCatatanPemberianResepPulang')" v-if="moduleRole('tambah_catatan') && (v.aranrrp_status == 'PROSES' || v.aranrrp_status == 'DONE')" class="btn btn-icon btn-sm rounded-round btn-sm alpha-indigo border-indigo text-indigo-800 mb-1" v-b-tooltip.hover
                            title="Tambah Catatan Pemberian Obat">
                            <i class="fa-solid fa-pencil"></i>
                          </a>

                          <a href="javascript:;" @click="toModalV2(v,'xl','Catatan Pemberian Obat','FormCatatanPemberianResepPulangDetail')" v-if="moduleRole('lihat_catatan') && (v.aranrrp_status == 'PROSES' || v.aranrrp_status == 'DONE')" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover
                            title="Lihat Catatan Pemberian Obat">
                            <i class="fas fa-file-prescription"></i>
                          </a>
                          
                          <a href="javascript:;" v-if="v.arantfar_status != 'WAITING'" @click="unduhDokumenPulang(v.aranrrp_id)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover title="Unduh Dokumen"><i class="icon-file-download"></i></a>
                                                
                        </td>
                      </tr>
                      <tr v-if="!(v.data||[]).length">
                        <td colspan="7" class="text-center">
                          <p class="mt-3">Belum ada data obat</p>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="!(dataPermintaanResepPulang||[]).length">
                      <tr>
                        <td colspan="99" class="text-center">
                          <p>Tidak ada Data</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>


        <b-modal
          v-model="openModal"
          :size="modalConfig.size"
          no-close-on-backdrop
          hide-footer
          :title="modalConfig.title" 
        >
          <FormCatatanPemberian v-if="isModal == 'FormCatatanPemberian'" />     
          <FormCatatanPemberianDetail v-if="isModal == 'FormCatatanPemberianDetail'" />
          <FormCatatanPemberianResepPulang v-if="isModal == 'FormCatatanPemberianResepPulang'" />
          <FormCatatanPemberianResepPulangDetail v-if="isModal == 'FormCatatanPemberianResepPulangDetail'" />
        </b-modal>

        <validation-observer ref="VFormStopObat">
          <b-modal v-model="openModalStop" :title="'Pembatalan Obat'" size="sm" @ok.prevent="stopObat">
            <div class="row">
              <div class="col-md-12">
                <label for="monitoringGizi">Alasan Pembatalan <strong class="text-danger">*</strong></label>
                <b-form-textarea v-model="alasanBatal" :formatter="normalText" name="Montoring" id="Montoring" rows="3" class="form-control"></b-form-textarea>
                <VValidate name="Alasan Pembatalan" v-model="alasanBatal"
                  :rules="{required: 1, min: 3, max: 128}" />
              </div>
            </div>
          </b-modal>
        </validation-observer>
      </template>
      <template v-else>
        <Form /> 
      </template>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Form from './Form.vue'
  import FormCatatanPemberian from './FormCatatanPemberian.vue'
  import FormCatatanPemberianDetail from './FormCatatanPemberianDetail.vue'

  import FormCatatanPemberianResepPulang from './FormCatatanPemberianResepPulang.vue'
  import FormCatatanPemberianResepPulangDetail from './FormCatatanPemberianResepPulangDetail.vue'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  const moment = require('moment')


  export default {
    extends: GlobalVue,
    components:{Form,FormCatatanPemberian,FormCatatanPemberianDetail,FormCatatanPemberianResepPulang,FormCatatanPemberianResepPulangDetail,PatientInfo},
    data() {
      return {
        rowReg: {},
        dataPermintaanResep: [],
        dataPermintaanResepPulang: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,
        resepObat: [],

        patientData: {},
        loading: {
            patientInfo: false,
        },
        openModalStop: false,
        alasanBatal: '',
        activeTabs: 0,
        mStatusPermintaanResep: [
          {
            text: "Semua Status",
            value: "ALL"
          },
          {
            text: "Belum Diproses",
            value: "WAITING"
          },
          {
            text: "Berjalan",
            value: "PROSES"
          },
          {
            text: "Sudah diberikan",
            value: "DONE"
          }
        ],
        statusFarmasi: 'ALL',
      }
    },
    computed: {
      isListDetail(){ return !this.$route.params.resepNo },  
      formName(){ return this.$route.query.form },   
      checkedObat(){
        let data = []
        for(let i = 0; i < (this.dataPermintaanResep||[]).length; i++){
          for(let j = 0; j < (this.dataPermintaanResep[i]['data']||[]).length; j++){
            if(this.dataPermintaanResep[i]['data'][j]['checked']){
              data.push(this.dataPermintaanResep[i]['data'][j]['arantfarr_id'])
            }
          }
        }
        return data
      }
    },
    methods: {
      changeTab(e){
        this.activeTabs = e
      },
      stopObat(){
        this.$refs['VFormStopObat'].validate().then(success => {
          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => { 
              if(result.value){
                let data = {
                  type: 'stop-obat',
                  pasienId: this.rowReg.aranr_ap_id,
                  id: this.checkedObat,
                  alasanBatal: this.alasanBatal
                }

                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + this.modulePage, {
                    data: data
                },
                "POST"
                ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
                  this.openModalStop = false
                  this.alasanBatal = null
                  this.apiGet(this.activeTabs)
                })
              }
            })
          } 
        })
      },  
      

      toModal(row,size,title,isModal){
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-farmasi-data', id: row.arantfar_aranr_id, idFarmasi: row.arantfar_id}}, 
            "POST"
        ).then(res=>{
          this.loadingOverlay = false
          this.resepObat = res.data.resepObat  
          this.resepObatView = res.data.resepObatView
          this.rowEdit = row
          this.modalConfig = {
            title: title,
            size: size
          }
          this.isModal = isModal
          this.openModal = true
        })
      },
      
      toModalV2(row,size,title,isModal){
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-farmasi-data-pulang', id: row.aranrrp_aranr_id, idFarmasi: row.aranrrp_id}}, 
            "POST"
        ).then(res=>{
          this.loadingOverlay = false
          this.resepObat = res.data.resepObat  
          this.rowEdit = row
          this.modalConfig = {
            title: title,
            size: size
          }
          this.isModal = isModal
          this.openModal = true
        })
      },
      
      getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
      },
      
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
      },
      
      apiGet(tabs,params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        let menu = 'permintaan-resep'
        if(tabs == 1){
          menu = 'permintaan-resep-pulang'
        }

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page,
              tabs: menu,
              status: this.statusFarmasi,
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      
      hapusTindakan(row){
        this.$swal({
          icon: 'warning',
          title: 'Hapus Data Ini ?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'

        }).then(result => {
          if(result.value){
            this.loadingOverlay = true
            let data = {
              id : row.arantfar_id
            }
            data.type = 'hapus-tindakan'
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
              this.loadingOverlay = false
              this.$swal({
                title: "Tindakan Berhasil DiHapus",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.apiGet(this.activeTabs)
            })
          }
        })
      },
      addTindakan(){
        this.loadingOverlay = true
        let data = {
          regId : this.rowReg.aranr_id
        }
        data.type = 'init-permintaan-resep'
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, resepNo: resp.arantfar_id}})
            .catch(()=>{})
        })
      },
      toForm(row,form=''){
        if(form){
          this.$router.push({ 
          name: this.modulePage, 
          params: {pageSlug: this.rowReg.aranr_id, resepNo: row.arantfar_id},
          query: {form: form}
          })
          .catch(()=>{})
        }else{
          this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, resepNo: row.arantfar_id}})
          .catch(()=>{})
        }
      },
      unduhDokumen(id){
        let data = {
          exptype: 'pdf', type: "export", pasienId: this.rowReg.aranr_ap_id, regId: this.rowReg.aranr_id, resepId: id        
        }
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/report/${'dataFarmasiRanap'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                  responseType: 'blob'
            },
            success: data => 
            {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `resep_${self.rowReg.aranr_reg_code}-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
            },
            fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
            }
        })
      },  
      unduhDokumenPulang(id){
        let data = {
          exptype: 'pdf', type: "export", pasienId: this.rowReg.aranr_ap_id, regId: this.rowReg.aranr_id, resepId: id        
        }
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/report/${'dataFarmasiRanapPulang'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                  responseType: 'blob'
            },
            success: data => 
            {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `resep_${self.rowReg.aranr_reg_code}-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
            },
            fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
            }
        })
      },
      downloadCatatanPemberian(){
        let data = {
          exptype: 'xlsx', type: "export", id: this.rowReg.aranr_id
        }
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/report/${'laporanCatatanPemberianResepAll'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
              responseType: 'blob'
            },
            success: data => 
            {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `resep_${self.rowReg.aranr_reg_code}-${moment().format("YYYY-MM-DD")}.xlsx`
              link.click()
            },
            fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
            }
        })
      },
    },
    mounted() {
      this.apiGet(this.activeTabs)
      this.getPatientInfo()
    },
    watch:{
      $route(){
        this.apiGet(this.activeTabs)
      },
      'activeTabs'(v){
        this.apiGet(v)
      }
    }
  }
</script>