<template>
  <div>

    <div class="row mb-3">
      <div class="col-md-12">
        <small class="text-info">*Data Pemberian Obat Pasien tidak termasuk Alat Kesehatan</small>
        <table class="table table-bordered">
          <div class="display-c" v-for="(v,k) in (isParent.resepObatView||[])" :key="k">
            <tbody>
              <tr>
                <td colspan="5" class="bg-info">
                  <div class="result_tab">
                    <h4 class="font-weight-bold text-white">Tanggal Pemberian</h4>
                    <p class="text-white">{{v.date|moment("DD MMMM YYYY")}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody class="custom-tbody">
              <tr>
                <th width="60%">Nama Obat</th>
                <th>Pagi</th>
                <th>Siang</th>
                <th>Sore</th>
                <th>Malam</th>
              </tr>
              <tr v-for="(v1,k1) in (v.data||[])" :key="k1">
                <td>
                  <div class="result-tab">
                    <p class="font-weight-semibold">{{v1.arantfarr_nama||"-"}}</p>
                    <div>
                      <span>{{v1.arantfarr_jumlah||"-"}}</span>
                      <span> {{v1.arantfarr_satuan||"-"}}</span>
                      <span class="ml-2">{{v1.mdo_name||"-"}}</span>
                        
                      <span v-if="v1.arantfarr_is_stop == 'Y'" v-b-tooltip.hover :title="v1.arantfarr_is_stop_reason" class="badge badge-danger ml-2">Obat Dihentikan</span>
                    </div>
                  </div>
                </td>
                <td>
                  <span>
                    {{v1.pagi}}
                  </span>
                  <template v-if="v1.pagi_is_done">
                    <span v-if="v1.pagi_status == 'Y'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.pagi_by" class="badge badge-success">Obat Sudah Diserahkan</span> 
                    <span v-else-if="v1.pagi_status == 'B'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.pagi_by" class="badge badge-info">Obat Belum Diserahkan</span> 
                    <span v-else-if="v1.pagi_status == 'N'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.pagi_by" class="badge badge-danger">Obat Tidak Diserahkan</span> 
                  </template> 
                </td>
                <td>
                  <span>                  
                    {{v1.siang}}
                  </span>
                  <template v-if="v1.siang_is_done">
                    <span v-if="v1.siang_status == 'Y'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.siang_by" class="badge badge-success">Obat Sudah Diserahkan</span> 
                    <span v-else-if="v1.siang_status == 'B'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.siang_by" class="badge badge-info">Obat Belum Diserahkan</span> 
                    <span v-else-if="v1.siang_status == 'N'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.siang_by" class="badge badge-danger">Obat Tidak Diserahkan</span> 
                  </template> 
                </td>
                <td>
                  <span>                  
                    {{v1.sore}}
                  </span>
                  <template v-if="v1.sore_is_done">
                    <span v-if="v1.sore_status == 'Y'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.sore_by" class="badge badge-success">Obat Sudah Diserahkan</span> 
                    <span v-else-if="v1.sore_status == 'B'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.sore_by" class="badge badge-info">Obat Belum Diserahkan</span> 
                    <span v-else-if="v1.sore_status == 'N'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.sore_by" class="badge badge-danger">Obat Tidak Diserahkan</span> 
                  </template> 
                </td>
                <td>
                  <span>                  
                    {{v1.malam}}
                  </span>
                  <template v-if="v1.malam_is_done">
                    <span v-if="v1.malam_status == 'Y'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.malam_by" class="badge badge-success">Obat Sudah Diserahkan</span> 
                    <span v-else-if="v1.malam_status == 'B'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.malam_by" class="badge badge-info">Obat Belum Diserahkan</span> 
                    <span v-else-if="v1.malam_status == 'N'" v-b-tooltip.hover.right :title="'Diserahkan Oleh : '+v1.malam_by" class="badge badge-danger">Obat Tidak Diserahkan</span> 
                  </template> 
                </td>
              </tr>
              <tr v-if="!(v.data||[]).length" colspan="99">
                <td colspan="99" class="text-center">
                  Tidak ada data
                </td>
              </tr>
            </tbody>
          </div>
          <tbody v-if="!(isParent.resepObatView||[]).length">
            <tr>
              <td colspan="99" class="text-center">Tidak ada data</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-2" v-if="(isParent.resepObatView||[]).length">
      <div class="col-md-12">
        <button @click="downloadLaporan" type="button" class="btn btn-primary" style="float: right;">Unduh Laporan</button>
      </div>
    </div>
  </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    jamPindah(){
      return moment().format('HH:mm')
    }
  },
  methods: {
    downloadLaporan(){
      let data = {
        exptype: 'xlsx', type: "export", id: this.isParent.rowEdit.arantfar_aranr_id, idFarmasi: this.isParent.rowEdit.arantfar_id  
      }
      let self = this
      self.loadingOverlay = true
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'laporanCatatanPemberianResep'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
            responseType: 'blob'
          },
          success: data => 
          {
            self.loadingOverlay = false
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `resep_${self.isParent.rowReg.aranr_reg_code}-${moment().format("YYYY-MM-DD")}.xlsx`
            link.click()
          },
          fail: data => {
            self.loadingOverlay = false
            alert('Not downloaded')
          }
      })
    }
  },
}

</script>
